import React from "react"
import CoursesCard from "./CoursesCard"
import OnlineCourses from "./OnlineCourses"

const CourseHome = () => {
  return (
    <>
      <OnlineCourses />
    </>
  )
}

export default CourseHome
